<template>
  <div v-if="uploadProgress === -1" class="prompt-wrapper">
    <h1 class="header-1">Create a unique 3D animation by prompt</h1>

    <div class="input-form">
      <textarea v-model="prompt" class="prompt-text" :class="{ 'error-border': characterCount > 255 }"
        placeholder="Write your prompt here..." @input="updateCharacterCount" rows="5"></textarea>

      <div class="input-form-bottom">
        <SecondBtn  class="action-btn"
                    :disabled="characterCount === 0"
                    @click="proccessPrompt">
            Create animation
        </SecondBtn>

        <div class="caption" :class="{ 'error-text': characterCount > 255 }">
          {{ characterCount }} / 255
        </div>
      </div>
    </div>
    
    <div class="caption input-label">
      Seconds
    </div>

    <base-input 
      class="seconds-input" 
      type="number" 
      placeholder="Seconds"
      v-model="seconds">
    </base-input>

    <div class="prompt-description caption">
      At the moment, only one preloaded 3D model is available for animation. We are already working on the feature for
      other 3D models.
    </div>
  </div>

  <div v-else class="progress-container">
    <div class="header-2">
      Please wait while the video is uploading and being processed
    </div>
    <div class="progress-placeholder">
      <svg class="progress-circle" width="100" height="100">
        <defs>
          <radialGradient id="progress-gradient" cx="50%" cy="50%" r="50%">
            <stop offset="26%" stop-color="#20AD8B" />
            <stop offset="76%" stop-color="#ACE41B" />
          </radialGradient>
        </defs>
        <g transform="rotate(-90, 50, 50)">
          <circle class="progress-circle-bg" cx="50" cy="50" r="45" fill="transparent" stroke="#eee"
            stroke-width="10" />
          <circle class="progress-circle-fg" cx="50" cy="50" r="45" fill="transparent" stroke="url(#progress-gradient)"
            stroke-width="10" :stroke-dasharray="circumference" :stroke-dashoffset="circumference - (uploadProgress / 100) * circumference
        " stroke-linecap="round" />
        </g>
      </svg>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import BaseInput from '@/components/BaseInput.vue';
import SecondBtn from '@/components/SecondBtn.vue';

import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();



const prompt = ref('');
const seconds = ref(5);
const characterCount = ref(0);

const loaderRadius = 45;
const circumference = computed(() => 2 * Math.PI * loaderRadius);

const uploadProgress = ref(-1);

const updateCharacterCount = () => {
  characterCount.value = prompt.value.length;
};

async function proccessPrompt() {
  if (characterCount.value >= 255) {
    alert('Prompt exceeds the maximum character limit.');
    return
  }

  const sessionId = store.getters.getSessionId;

  const promptData = {
    prompt: prompt.value,  
    duration: seconds.value,  
    fps: 30,  
    temperature: 0.7,  
    name: "default"
  };

const url = `${process.env.VUE_APP_BACKEND_URL}/prompt/`;

  try {
    simulateProcessing();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'session-id': sessionId
      },
      body: JSON.stringify(promptData),
    });

    if (!response.ok) {
      console.error("Failed to start prompt processing: " + response.status);
      alert("Failed to start prompt processing");
      resetUploadState();
      return;
    }

    const result = await response.json();
    const animationId = result.animation_id;
    router.push({ path: "/animation", query: { animationId: animationId } });
  } catch (error) {
    console.error("Error processing prompt:", error);
    alert("Error processing prompt");
    resetUploadState();
  } finally {
    resetUploadState();
  }
}

function simulateProcessing() {
  const totalTime = 100000;
  const intervalTime = 100;
  const increments = totalTime / intervalTime;
  let currentProgress = uploadProgress.value;

  const interval = setInterval(() => {
    currentProgress += 100 / increments;
    if (currentProgress >= 100) {
      clearInterval(interval);
      uploadProgress.value = 99;
    } else {
      uploadProgress.value = currentProgress;
    }
  }, intervalTime);
}

function resetUploadState() {
  uploadProgress.value = -1;
}
</script>

<style scoped>
.header-1 {
  text-align: center
}

.prompt-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 60%;
}

.input-form,
.prompt-description {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
}

.prompt-text {
  width: 100%;
  background: transparent;
  border: none;
  color: #fff;
}

.prompt-text:focus {
  outline: none;
  border: none;
}

.prompt-text.error-border {
  border: 1px solid red;
}

.input-form-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prompt-send-btn {
  padding: 9px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  border: none;
}

.prompt-send-btn:disabled {
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.2);
  cursor: not-allowed;
}

.caption {
  color: rgba(255, 255, 255, 0.4);
}

.caption.error-text {
  color: red;
}

.seconds-input {
  width: 100%;
}

.progress-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progress-placeholder {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 12px;
}

.progress-circle-bg,
.progress-circle-fg {
  transition: stroke-dashoffset 0.3s linear;
}

.input-label {
  width: 100%;
  text-align: left;
}

@media (max-width: 768px) {
  .prompt-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 80%;
  }
}
</style>